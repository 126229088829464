import React from 'react';
import './chiaki.css';

export default function MoodChiaki() {

    const filenames = [
        '050',
        '100',
        '110',
        '300',
        '360',
        '500',
        '510',
        '550',
        '555',
        '560',
        '590',
        '600',
        '750',
        '800',
    ];

    const referenceFilenames = [
        'beaton106.jpg',
        'dahl-wolfe111.jpg',
        'hido001.jpg',
        'newjeans007.jpg',
        'rand115.jpg',
    ];

    return (
        <div className="chiaki-display">
        {filenames.map((filename, index) => (
            <div className="chiaki__image-wrapper">
                <img src={`https://aight-images.s3.amazonaws.com/moods/chiaki/${filename}.jpg`} />
                <div className="chiaki-num">{index + 1}</div>
            </div>
        ))}
        {referenceFilenames.map((filename, index) => (
            <div className="chiaki__image-wrapper">
                <img src={`https://aight-images.s3.amazonaws.com/reference/${filename}`} />
                <div className="chiaki-num">{filenames.length + index + 1}</div>
            </div>
        ))}
        </div>
    );
};